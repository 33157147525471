import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {AlertController, ModalController} from '@ionic/angular';
import {ActivatedRoute, Router} from '@angular/router';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {HomePage} from '../home/home.page';
import {Api} from '../../../smoothr-web-app-core/api/api';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import {environment} from '../../../environments/environment';
import {AnalyticsService} from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import {
	defaultsToArticleOption,
	firstOfObservable,
	numberD,
	numberToCurrency,
	sleep
} from '../../../smoothr-web-app-core/utils/utils';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {RequestEmailComponent} from './request-email/request-email.component';
import Payment from 'src/smoothr-web-app-core/models/Payment';
import Order from 'src/smoothr-web-app-core/models/Order';
import {OrderType} from 'src/smoothr-web-app-core/enums/OrderType';
import Article from 'src/smoothr-web-app-core/models/Article';
import {ValidationUtils} from 'src/smoothr-web-app-core/utils/validation-utils';
import OptionGroup from 'src/smoothr-web-app-core/models/OptionGroup';
import ArticleGroup from 'src/smoothr-web-app-core/models/ArticleGroup';
import {MenuPage} from '../menu/menu.page';

@Component({
	selector: 'app-payment-success',
	templateUrl: './payment-success.page.html',
	styleUrls: ['payment-success.page.scss']
})
export class PaymentSuccessPage extends RepositoryDirective implements OnInit {
	static url = 'payment/success';
	static urlWithPaymentParam = 'payment/success/:payment';
	static ORDER_ID_PARAM = 'order';
	moment = moment;
	OrderUtils = OrderUtils;
	pt = PreorderType;
	orderVenue: Venue;
	error: any;
	orderId: string;
	environment = environment;
	numberToCurrency = numberToCurrency;
	loading = true;
	tip: number = 0;
	isSuccessPage = false;
	paymentValue: Payment;
	recommendationArticles: Article[] = [];
	slideOptions = {
		spaceBetween: 0,
		initialSlide: 0,
		speed: 400,
		allowTouchMove: true,
		// loop: true,
		autoplay: true,
		slidesPerView: 2,
		zoom: false,
		preloadImages: true
	};
	constructor(
		private translate: TranslateService,
		protected repository: RepositoryService,
		private alertCtrl: AlertController,
		private router: Router,
		private snackbarCtrl: MatSnackBar,
		private route: ActivatedRoute,
		private analytics: AnalyticsService,
		private modalCtrl: ModalController
	) {
		super(repository);
	}

	static async navigate(router: Router, order: string[], payment: string) {
		await router.navigate([this.url + '/' + payment], {
			queryParams: {
				order
			}
		});
	}

	ionViewDidEnter() {
		console.log(this.verifiedOrder);
		this.repository.order.emit(null);
	}

	async onInitFinish() {
		super.onInitFinish();
		this.route.queryParams.subscribe(params => {
			console.log({
				params
			});
			if (this.verifiedOrder && this.verifiedOrder._id && !params.order) {
				this.orderId = this.verifiedOrder._id;
			} else {
				this.orderId = params.order;
			}
			this.reloadOrder();
		});
	}
	async loadRecommendationsToVenue(venue: Venue, order: Order) {
		try {
			const objectData = {
				venue: order.venue,
				articles: order.orderedArticles.map(it => {
					return {_id: it.article._id, quantity: it.quantity};
				}),
				orderType: OrderType.PREORDER,
				subOrderType: order.preorder.type === PreorderType.TAKE_AWAY ? 'takeAway' : 'inside',
				maxBestTags: 30
			};
			const result = (await Api.getCartRecommendations(objectData)).data;
			if (this.venue && result.length > 0) {
				this.recommendationArticles = [];

				const categoryArticles = [].concat(...this.venue.articleCategories.map(it => it.articles)) as Article[];
				const categoryArticlesMap = new Map(categoryArticles.map(it => [it._id, it]));
				result.forEach(it => {
					it.articles.forEach(art => {
						if (categoryArticlesMap.has(art)) {
							this.recommendationArticles.push(categoryArticlesMap.get(art));
						}
					});
				});
				console.log(this.recommendationArticles);
			} else {
				this.recommendationArticles = [];
			}
		} catch (e) {}
	}
	async reloadOrder(attempt: number = 0, error = null) {
		this.error = null;
		this.loading = true;
		if (attempt > 10) {
			this.error = error ? error : this.translate.instant('payment_success_page.payment_error');
			this.snackbarCtrl.open(error, null, {
				duration: 2000
			});
			this.loading = false;
			return;
		}
		if (attempt > 0) {
			await sleep(200);
		}
		try {
			const orderResponse = await Api.getOrder(this.orderId);
			this.repository.verifiedOrder.emit(orderResponse.data);
			if (!this.verifiedOrder.isPayed) {
				await this.reloadOrder(attempt + 1, this.translate.instant('payment_success_page.please_reload'));
				return;
			}
			const params = await firstOfObservable(this.route.params);
			if (params.payment) {
				this.paymentValue = (await Api.getPayment(params.payment)).data;
				this.tip = numberD(this.paymentValue.tip);
			}
			this.analytics.paymentSuccess();
			try {
				if (this.venue && this.venue._id === this.verifiedOrder.venue) {
					this.orderVenue = this.venue;
				} else {
					this.orderVenue = await this.repository.getVenue(this.verifiedOrder.venue);
				}
				this.error = false;
				this.loading = false;
				//TODO: turn on when needed
				// this.loadRecommendationsToVenue(this.orderVenue, orderResponse.data);
			} catch (e) {
				console.error(e);
				await this.reloadOrder(attempt + 1, error ? error : e);
				return;
			}
		} catch (e) {
			console.error(e);
			await this.reloadOrder(attempt + 1, e);
			return;
		}
	}

	home() {
		if (this.loading) {
			return;
		}
		if (this.error) {
			this.reloadOrder();
			return;
		}
		this.loading = true;
		HomePage.navigate(this.router, this.repository);
	}

	async openRequestEmail() {
		const result = await RequestEmailComponent.show(this.modalCtrl, this.paymentValue, this.orderVenue);
		this.isSuccessPage = result;
	}

	openReviewHyperlink(venue: Venue) {
		try {
			console.log(venue);
			const url = (venue as Venue & {reviewHyperlink: string}).reviewHyperlink;

			window.open(url, '_blank');
		} catch (error) {
			console.log(error);
		}
	}
	async addArticle(article: Article) {
		console.log('ADD art');
		this.repository.createOrder(this.orderVenue, null, this.verifiedOrder.preorder.type, null, null, '');
		await sleep(300);
		this.order.tableNumber = this.verifiedOrder.tableNumber;
		this.order.table = this.verifiedOrder.table;
		this.repository.order.emit(this.order);
		this.router.navigate(['/' + MenuPage.url], {
			queryParams: {
				articleId: article._id
			}
		});
	}
	calcArticlePrice(art: Article) {
		if (!this.verifiedOrder || !art) {
			return '';
		}
		return this.reloadPrice(art, this.verifiedOrder);
	}
	private reloadPrice(articleValue: Article, order: Order) {
		const articleGroup = new ArticleGroup();
		articleGroup.article = articleValue;
		articleGroup.groups = defaultsToArticleOption(articleValue, [], [], order.preorder.type);

		articleGroup.quantity = 1;

		const article = articleGroup.article;
		let price = numberD(article.price);
		const processedGroups: string[] = [];
		article.groups.forEach(grp => {
			if (processedGroups.indexOf(grp._id) >= 0) return;
			if (ValidationUtils.isGroupDependencyFulfilled(articleGroup.article, articleGroup.groups, grp).times < 0) return;
			if (grp.requiredAmount == grp.limit) {
				const minimumItem = this.minOfItem(grp.articles, opt => numberD(opt.price));

				price += numberD(minimumItem.price) * grp.requiredAmount;
				const dependants = this.dependenciesRegardingOptionArticle(article, article.groups, minimumItem);
				dependants.forEach(dependant => {
					const dependantMinimumItem = this.minOfItem(dependant.articles, opt => numberD(opt.price));
					price += grp.requiredAmount * dependant.requiredAmount * numberD(dependantMinimumItem.price);
					processedGroups.push(dependant._id);
				});
				processedGroups.push(grp._id);
			}
		});
		return numberToCurrency(price, this.verifiedOrder.currency);
	}
	private minOfItem<T>(items: T[], transformer: (item: T) => number): T {
		if (items.length === 1) {
			return items[0];
		}
		const values = items.map(item => transformer(item));
		const min = Math.min(...values);
		const index = values.indexOf(min);
		return items[index];
	}
	dependenciesRegardingOptionArticle(article: Article, groups: OptionGroup[], optionArticle: Article): OptionGroup[] {
		return article.groupDependencies
			.map(gd => {
				if (gd.dependencies.find(dependeci => dependeci.groupArticles.includes(optionArticle._id)) == null) {
					return null;
				} else {
					return groups.find(it => gd.group === it._id);
				}
			})
			.filter(it => !!it);
	}
}
