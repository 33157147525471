import {HomePage} from './../home/home.page';
import {Component, OnInit, ViewChild} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import ArticleCategory from '../../../smoothr-web-app-core/models/ArticleCategory';
import {ActivatedRoute, Router} from '@angular/router';
import {ToOrderButtonComponent} from '../../components/to-order-button/to-order-button.component';
import {AlertController, IonSearchbar, ModalController} from '@ionic/angular';
import {ModalInfoComponent} from '../../components/modal-info/modal-info.component';
import Article from '../../../smoothr-web-app-core/models/Article';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import {AppComponent} from '../../app.component';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import * as moment from 'moment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {OrderPage} from '../order/order.page';
import {TranslateService} from '@ngx-translate/core';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {environment} from '../../../environments/environment';
import {
	axiosErrorToMessage,
	defaultsToArticleOption,
	getAvailability,
	sleep
} from '../../../smoothr-web-app-core/utils/utils';
import {AnalyticsService} from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import {SelectTableModalComponent} from 'src/app/components/select-table-modal/select-table-modal.component';
import {SelectLanguageModalComponent} from 'src/app/components/select-language-modal/select-language-modal.component';
import Tag from 'src/smoothr-web-app-core/models/Tag';
import {RecomendationArticleModalComponent} from 'src/app/components/recomendation-article-modal/recomendation-article-modal.component';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.page.html',
	styleUrls: ['menu.page.scss']
})
export class MenuPage extends RepositoryDirective implements OnInit {
	static url = 'menu';

	@ViewChild(ToOrderButtonComponent, {static: false}) toOrderButton;
	@ViewChild('articleContent', {static: false}) articleList;
	@ViewChild(IonSearchbar, {static: false}) searchBar;
	@ViewChild('categoryList', {static: false}) categoryList;

	environment = environment;
	scrollEvents = true;
	loading = false;
	searchTerm = '';
	categories: ArticleCategory[] = [];
	moment = moment;
	PreorderType = PreorderType;
	searchBarOpen = false;

	showBusket = false;

	get preorderType(): PreorderType {
		return this.order.preorder?.type;
	}

	checkedMainTagCategories = false;

	mainTagCategories = [];

	preMainTagCategories = [
		{
			name: {
				en: 'We recommended',
				es: 'Recomendamos'
			},
			tag: 'we_recommend'
		},
		{
			name: {
				en: 'Food',
				es: 'Comida'
			},
			tag: 'food'
		},
		{
			name: {
				en: 'Drinks',
				es: 'Bebidas'
			},
			tag: 'drinks'
		}
	];
	constructor(
		public translate: TranslateService,
		protected repository: RepositoryService,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private route: ActivatedRoute,
		private alertCtrl: AlertController,
		private router: Router,
		private analytics: AnalyticsService
	) {
		super(repository);
	}

	private _selectedCategoryIndex = 0;

	get selectedCategoryIndex(): number {
		return this._selectedCategoryIndex;
	}
	private _selectedShopValue: string = 'we_recommend';

	get selectedShopCategory() {
		return this._selectedShopValue;
	}

	// set selectedShopValue(value: 'we_recommend' | 'Food' | 'Drinks') {

	// }

	set selectedCategoryIndex(value) {
		if (this._selectedCategoryIndex === value) {
			return;
		}
		this._selectedCategoryIndex = value;
		this.scrollTo(value);
	}

	get selectedCategory(): ArticleCategory {
		return this.categories[this.selectedCategoryIndex];
	}

	get active(): boolean {
		return (
			this.order &&
			OrderUtils.articleGroupsTotalPrice(
				this.order.orderedArticles,
				this.order.type,
				this.order.preorder.type,
				this.order.terminalorder ? this.order.terminalorder.type : null,
				''
			) > 0
		);
	}

	get index(): number {
		return this.categories.findIndex(category => category._id === this.selectedCategory._id);
	}

	static async navigate(router: Router) {
		await router.navigateByUrl(MenuPage.url);
	}

	async ngOnInit() {
		await super.ngOnInit();
		switch (window.location.pathname) {
			case '/' + OrderPage.url:
				break;
			case '/' + OrderPage.paymentCancelUrl:
				console.log(this.translate.instant('order.payment_cancel'));
				this.snackbarCtrl.open(this.translate.instant('order.payment_cancel'), null, {
					duration: 2000
				});
				break;
			case '/' + OrderPage.paymentFailUrl:
				console.log(this.translate.instant('order.payment_fail'));
				this.snackbarCtrl.open(this.translate.instant('order.payment_fail'), null, {
					duration: 5000
				});
				break;
		}

		this.loading = true;
		this.repository.venue.emit(
			await this.repository.getVenue(
				this.order && this.order.venue ? this.order.venue : environment.customerGroup + '_master'
			)
		);
		this.reloadCategories();
		this.openSelectTableModal();

		this.loading = false;
		let showArticle: Article = null;
		this.route.queryParams.subscribe(param => {
			console.log('param', param);
			if ((param?.articleId, this.venue?.articleCategories?.length > 0)) {
				this.venue.articleCategories.forEach(it => {
					if (it?.articles?.length > 0)
						(it.articles ?? []).forEach(art => {
							if (art?._id === param?.articleId) {
								showArticle = art;
							}
						});
				});
			}
		});

		if (showArticle) {
			this.openModal(showArticle, true);
		}
	}

	largeScreen(): boolean {
		return AppComponent.largeScreen;
	}

	async scrollTo(index: number) {
		if (this.categories.length - 1 < index || index < 0) {
			return;
		}
		if (this.searchTerm !== '') {
			await sleep(100);
			await this.scrollTo(index);
			return;
		}
		this.scrollEvents = false;
		if (this.selectedCategoryIndex !== index) {
			this.selectedCategoryIndex = index;
			await this.scrollArticleListTo(index);
			await this.scrollCategoryListTo(index);
		}
		this.scrollEvents = true;
	}

	async onScroll(event) {
		const categoryElements = [...this.articleList.el.children];
		const categoryIndex = categoryElements
			.map(el => el.offsetTop)
			.map((value, index, array) => {
				return (
					value <= event.detail.currentY &&
					((index < array.length - 1 && event.detail.currentY < array[index + 1]) || index === array.length - 1)
				);
			})
			.findIndex(value => value);
		let prevIndex = -1;

		if (this.selectedCategory) {
			prevIndex = this.categories.findIndex(category => category._id === this.selectedCategory._id);
		}
		if (prevIndex === categoryIndex) {
			return;
		}
		if (this.scrollEvents) {
			this.selectedCategoryIndex = categoryIndex;
			await this.scrollCategoryListTo(categoryIndex);
		}
	}

	private async scrollCategoryListTo(index: number) {
		if (
			index < 0 ||
			!this.categoryList?.nativeElement?.children[index] ||
			(!this.categoryList?.nativeElement?.scrollTo && !this.categoryList?.nativeElement?.scrollLeft)
		) {
			return;
		}
		this.categoryList.nativeElement.scrollTo(this.categoryList.nativeElement.children[index].offsetLeft - 25, 0);
		await sleep(100);
	}
	async openModal(item: Article, showRec: boolean = true) {
		if (this.loading) {
			return;
		}
		if (
			this.order.tableNumber === 'preorder_table' &&
			this.order.preorder &&
			this.order.preorder.type === PreorderType.INSIDE
		) {
			this.openSelectTableModal();
			return;
		}
		const articleGroup = new ArticleGroup();
		articleGroup.article = item;
		articleGroup.groups.push(...defaultsToArticleOption(item, [], item.defaults, this.order.preorder.type));
		articleGroup.quantity = 1;
		const modal = await this.modalCtrl.create({
			cssClass: AppComponent.largeScreen ? 'item-modal large-modal' : 'item-modal',
			component: ModalInfoComponent,
			componentProps: {
				articleGroup
			},
			mode: 'ios',
			backdropDismiss: true
		});
		await modal.present();
		const response = await modal.onDidDismiss();
		if (response.data && response.data.articleGroup) {
			OrderUtils.addToOrder(this.order, response.data.articleGroup, this.analytics);
			this.repository.order.emit(this.order);
			if (showRec) {
				const mainRecommendations = item.mainRecommendations;

				if (mainRecommendations.length > 0) {
					const categoryArticles = [].concat(...this.venue.articleCategories.map(it => it.articles)) as Article[];
					const categoryArticlesMap = new Map(categoryArticles.map(it => [it._id, it]));
					const recommendedArticles: Article[] = [];

					mainRecommendations.forEach(it => {
						if (categoryArticlesMap.has(it)) {
							recommendedArticles.push(categoryArticlesMap.get(it));
						}
					});
					const result = await RecomendationArticleModalComponent.show(this.modalCtrl, recommendedArticles, this.order);
					this.modalCtrl.dismiss();
					if (result?.article) {
						this.openModal(result.article, false);
					}
				}
			}
		}
	}

	onSearchTermChanged(event) {
		this.searchTerm = event.detail.value;
		this.reloadCategories();
	}

	reloadCategories(
		params: {
			categoryId: string;
			articleTagIndentifier: string;
		} = null
	) {
		if (!this.venue) {
			this.categories = [];
			return;
		}

		const lowerSearchTerm = this.searchTerm && this.searchTerm !== '' ? this.searchTerm.toLocaleLowerCase() : null;
		this.categories = this.venue.articleCategories
			.filter(category => !category.hidden)
			.filter(category => {
				if (this.selectedShopCategory && !lowerSearchTerm) {
					return !!category.tags.find(tag => tag.identifier === this.selectedShopCategory);
				} else {
					return false;
				}
			})

			.map(category => {
				if (params && category._id === params.categoryId) {
					category.filterArticleByTag = params.articleTagIndentifier;
				}
				const cat: ArticleCategory = JSON.parse(JSON.stringify(category));
				cat.articles = cat.articles.filter(article => {
					let available = getAvailability(article, OrderType.PREORDER, this.preorderType);
					if (lowerSearchTerm) {
						const keys = [
							article.name.de.toLocaleLowerCase(),
							article.name.en.toLocaleLowerCase(),
							cat.name.de.toLocaleLowerCase(),
							cat.name.en.toLocaleLowerCase()
						];
						return available && keys.map(key => key.indexOf(lowerSearchTerm)).find(result => result >= 0) !== undefined;
					}
					if (category.filterArticleByTag && article.visible) {
						article.visible = !!article.tags.find(it => it.identifier === category.filterArticleByTag);
					}
					return available;
				});
				return cat;
			})
			.filter(category => category.articles.length > 0);
		return;
	}

	onVenue() {
		super.onVenue();
		if (!this.venue) {
			this.repository
				.getVenue(this.order && this.order.venue)
				.then(venue => {
					this.repository.venue.emit(venue);
				})
				.catch(_ => {
					this.reloadCategories();
					this.selectedCategoryIndex = 0;
				});
			return;
		}
		this.reloadCategories();

		if (!this.checkedMainTagCategories && this.venue) {
			this.mainTagCategories = this.preMainTagCategories.filter(mainCategory => this.isCategoriesInside(mainCategory));
			if (this.mainTagCategories[0]?.tag) {
				this._selectedShopValue = this.mainTagCategories[0]?.tag;
			}
			this.checkedMainTagCategories = true;
			console.log(this._selectedShopValue);

			return;
		}
		this.selectedCategoryIndex = 0;
	}

	onVenueError(error) {
		super.onVenueError(error);
		this.snackbarCtrl.open(axiosErrorToMessage(this.translate, error));
		this.loading = false;
		this.snackbarCtrl.open(
			this.translate.instant('menu_page.venue_timed_out'),
			this.translate.instant('menu_page.venue_timed_out_action')
		);
	}

	private async scrollArticleListTo(index: number) {
		if (!this.articleList) {
			return;
		}
		await this.articleList.scrollToPoint(0, this.articleList.el.children[index]?.offsetTop + 1, 300);
	}
	goToHome() {
		HomePage.navigate(this.router);
	}

	changeSearchbar() {
		this.searchBarOpen = !this.searchBarOpen;
	}

	clearInput() {
		this.searchTerm = '';
		this.searchBarOpen = !this.searchBarOpen;
		this.reloadCategories();
	}
	async selectLanguageModal() {
		await SelectLanguageModalComponent.show(this.modalCtrl);
	}
	async openSelectTableModal(openByToolbar?: boolean) {
		if (this.venue && this.order.preorder.type === PreorderType.INSIDE) {
			if (this.order.tableNumber === 'preorder_table' || openByToolbar) {
				const selectTable = await SelectTableModalComponent.show(this.modalCtrl, this.venue, this.order);
				if (selectTable) {
					this.order.tableNumber = selectTable.table;
					this.order.table = selectTable.tableId;
					this.repository.order.emit(this.order);
				}
			}
		}
	}

	isRecommendCategory(tagCategory: {name: string; tag: string}): boolean {
		return tagCategory.tag === 'we_recommend';
	}

	selectShop(value: string) {
		this._selectedShopValue = value;
		this.reloadCategories();
		// this.scrollToTag(value, null);
		this.scrollTo(0);
	}
	hasTagsCategoryArticles(articles: Article[]) {
		if (articles.length === 0) {
			return null;
		}
		articles.map(it => {
			it.tags = it.tags.filter(it => it.identifier.includes(''));
			return it.tags;
		});
	}
	checkIfCategoryHasTag(category: ArticleCategory) {
		return category.tags.length > 0 && category.tags.find(it => it.identifier.includes('has_filter'));
	}

	getArrayOfFilters(articles: Article[]): Tag[] {
		console.log({...articles.find(article => article._id === '66068f2303ce98995bd3527a')});

		const listOfTags = [].concat(
			...articles.map(article => {
				const articleTags = article.tags.filter(it => it.identifier.includes('filter_'));
				return articleTags;
			})
		);

		return listOfTags.filter((a, i) => listOfTags.findIndex(s => a.identifier === s.identifier) === i);
	}

	trackBy(index: number, item: ArticleCategory) {
		return item._id;
	}
	trackByArticle(index: number, item: Article) {
		return item._id;
	}
	addCategoryArticleFilter(categoryId: string, articleTagIndentifier: string) {
		this.reloadCategories({categoryId: categoryId, articleTagIndentifier: articleTagIndentifier});
	}

	isCategoriesInside(mainCategory: {name: {en: string; es: string}; tag: string}): boolean {
		console.log(this.venue.articleCategories);

		if (!this.venue || !this.venue.articleCategories) {
			return true;
		}

		const isExists = this.venue.articleCategories
			.filter(category => !category.hidden)
			.some(category => category.tags.some(tag => tag.identifier === mainCategory.tag));

		return isExists;
	}
}
